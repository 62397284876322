<template>
  <v-card
    width="400px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Diarsipkan</span>
      <input-icon-toggle
        v-model="search"
        class="ms-2"
        :icon="icons.mdiMagnify"
        placeholder="Cari job diarsipkan"
        @input="onSearch"
        @close="closeSearch"
      />
    </div>
    <div
      id="archivedProduct"
      v-scroll:#archivedProduct="listenScrollFetchMoreProduct"
      class="job-list mt-2"
    >
      <v-card
        v-for="item in productList"
        :key="item.id"
        outlined
        class="d-flex flex-column flex-grow-1 pa-4"
      >
        <div class="d-flex mb-1 align-start">
          <div>
            <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ item.name }}</span>
            <span class="text-caption font-medium">Other Detail</span>
          </div>
          <v-btn
            outlined
            small
            text
            class="ml-auto"
            @click="restoreProductFunc(item.id)"
          >
            Pulihkan
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import Vue from 'vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import useProduct from '@/composables/useProduct'
import { archiveProduct } from '@/graphql/mutations'

export default {
  components: {
    InputIconToggle,
  },
  props: {
    isArchiveOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const filter = ref({ is_archive: true })
    const search = ref('')
    const {
      productFilter, productList, listenScrollFetchMoreProduct, fetchProducts, debouncedFetchProducts,
    } = useProduct({ filter, search })
    const isOpen = computed(() => props.isArchiveOpen)
    watch(isOpen, () => {
      fetchProducts()
    })

    onMounted(() => {
      fetchProducts()
    })

    const restoreProductFunc = id => {
      Vue.$dialog({
        title: 'Pulihkan produk?',
        body: 'Yakin ingin pulihkan produk?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveProduct,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: false,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            emit('update')
            productFilter.value.filter.is_archive = true
            fetchProducts()
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil memulihkan produk!',
            })
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }
    const closeSearch = () => {
      productFilter.value.filter.search = null
      fetchProducts()
    }

    const onSearch = value => {
      search.value = value
      debouncedFetchProducts()
    }

    return {
      search,
      filter,
      productList,
      restoreProductFunc,
      listenScrollFetchMoreProduct,
      closeSearch,
      onSearch,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.job-list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
