<template>
  <div>
    <div class="d-flex">
      <v-text-field
        v-model="inputData.min"
        type="number"
        placeholder="Min"
        outlined
        dense
        hide-details
        class="mr-1"
      />
      <v-text-field
        v-model="inputData.max"
        type="number"
        placeholder="Max"
        outlined
        dense
        hide-details
        :error="validation.length > 0"
        class="ml-1"
      />
    </div>
    <span class="d-block text-caption error--text mt-1">{{ validation }}</span>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        min: 0,
        max: 0,
      }),
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const validation = computed(() => {
      const min = +props.value.min
      const max = +props.value.max

      if (max > 0 && min > 0 && min >= max) {
        return 'Nominal max harus lebih besar'
      }

      return ''
    })

    return {
      inputData,
      validation,
    }
  },
}
</script>

<style>

</style>
