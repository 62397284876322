<template>
  <v-item-group class="v-btn-toggle split-button">
    <v-btn
      color="primary"
      :outlined="outlined"
      :small="small"
      class="rounded-r-0"
      :style="outlined ? 'border-right: none' : null"
      @click="$emit('click')"
    >
      <slot name="default">
        {{ label }}
      </slot>
    </v-btn>
    <v-menu
      offset-y
      :left="!menuRight"
      :right="menuRight"
      nudge-bottom="8px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="color"
          :outlined="outlined"
          :small="small"
          class="rounded-l-0 split-toggle"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <slot
          name="list"
        />
      </v-list>
    </v-menu>
  </v-item-group>
</template>

<script>
import { mdiMenuDown } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: mdiMenuDown,
    },
    color: {
      type: String,
      default: 'primary',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    menuRight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.split-button {
  .split-toggle {
    min-width: 40px !important;
    padding: 0 12px !important;
  }
  .v-btn {
    opacity: 1 !important;
  }
  .v-btn:not(.v-btn--outlined) .v-icon {
    color: #fff !important;
  }
}
</style>
