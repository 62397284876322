import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px"},on:{"input":function($event){!$event ? _vm.$emit('close') : null}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('span',{staticClass:"text-h6 font-medium primary--text"},[_vm._v("Filter Produk")]),_c(VIcon,{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-caption mb-1 d-block"},[_vm._v("Cari Produk")]),_c(VTextField,{attrs:{"placeholder":"Cari nama atau SKU produk","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.state.productFilter.value.search),callback:function ($$v) {_vm.$set(_vm.state.productFilter.value, "search", $$v)},expression:"state.productFilter.value.search"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-caption mb-1 d-block"},[_vm._v("Harga Produk")]),_c('min-max-input',{model:{value:(_vm.state.productFilter.value.price),callback:function ($$v) {_vm.$set(_vm.state.productFilter.value, "price", $$v)},expression:"state.productFilter.value.price"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-caption mb-1 d-block"},[_vm._v("Stok Produk")]),_c('min-max-input',{model:{value:(_vm.state.productFilter.value.stock),callback:function ($$v) {_vm.$set(_vm.state.productFilter.value, "stock", $$v)},expression:"state.productFilter.value.stock"}})],1)],1),_c('div',{staticClass:"d-flex flex-column flex-md-row mt-4"},[_c(VBtn,{staticClass:"mb-2 mb-md-0 mr-md-2 ml-md-auto",attrs:{"outlined":""},on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v(" Reset Filter ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.filter}},[_vm._v(" Filter Produk ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }