<template>
  <v-tabs-items
    v-model="currentTab"
    touchless
  >
    <v-tab-item>
      <div v-if="currentTab === 0">
        <v-row class="match-height mt-2">
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              outlined
              class="pa-3"
            >
              <div class="d-flex mb-2">
                <v-chip small>
                  1
                </v-chip>
                <span class="text-headline font-medium ml-2">Download Template</span>
              </div>
              <span class="d-block text-subtitle-2 font-weight-regular">
                Silahkan download salah satu template di bawah ini untuk menambahkan banyak produk sekaligus. Pastikan data produk sudah sesuai format agar terhindar dari kesalahan input.
              </span>
              <div class="d-flex mt-3">
                <v-card
                  outlined
                  class="py-2 px-3 d-flex mr-2"
                  href="https://oriens.node.zoomit.co.id/assets/import/product.xlsx"
                >
                  <v-icon class="mr-2 primary--text">
                    {{ icons.mdiFile }}
                  </v-icon>
                  <span class="text-subtitle-1 primary--text">
                    .XLSX
                  </span>
                </v-card>
                <v-card
                  outlined
                  class="py-2 px-3 d-flex mr-2"
                  href="https://oriens.node.zoomit.co.id/assets/import/product%20CSV.csv"
                >
                  <v-icon class="mr-2 primary--text">
                    {{ icons.mdiFile }}
                  </v-icon>
                  <span class="text-subtitle-1 primary--text">
                    .CSV
                  </span>
                </v-card>
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              outlined
              class="pa-3"
            >
              <div class="d-flex mb-4">
                <v-chip small>
                  2
                </v-chip>
                <span class="text-headline font-medium ml-2">Upload File Template</span>
              </div>
              <drop-file
                ref="filePicker"
                max-file="1"
                max-size="5000"
                subtitle="Upload file template yang sudah diisi."
                disable-preview
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                @fileAdded="isValid = true"
                @fileDeleted="isValid = false"
              />
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex flex-column flex-md-row mt-4">
          <v-btn
            color="primary"
            text
            outlined
            @click="$emit('previous')"
          >
            Kembali
          </v-btn>
          <v-btn
            class="ml-auto"
            color="primary"
            :loading="loadingUpload"
            :disabled="!isValid"
            @click="upload"
          >
            Selanjutnya
          </v-btn>
        </div>
      </div>
    </v-tab-item>
    <v-tab-item class="d-flex flex-column">
      <div v-if="currentTab === 1">
        <span class="text-subtitle-1">Produk Duplikat</span>
        <v-alert
          border="left"
          color="warning"
          dense
          text
          type="info"
          class="mb-4 mt-2 text-subtitle-2 py-2"
        >
          <span class="text-caption">Ditemukan beberapa data duplikat, kamu bisa menghapus atau melanjutkan import data.</span>
        </v-alert>
        <v-row dense>
          <v-col
            v-for="product in duplicateProduct"
            :key="product.existing_product_id"
            cols="12"
            md="4"
          >
            <v-card
              outlined
              class="pa-3 d-flex align-center"
            >
              <div class="d-flex flex-column">
                <span class="text-subtitle-2 font-weight-bold d-block">{{ product.name }}</span>
                <span class="text-caption font-medium">{{ product.code_sku }}</span>
                <span class="text-caption font-medium">{{ product.price }}</span>
              </div>
              <v-btn
                class="ml-auto rounded"
                outlined
                text
                icon
                color="error"
                @click="deleteProduct(product.existing_product_id)"
              >
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex flex-column flex-md-row mt-4 mb-2">
          <v-btn
            color="primary"
            text
            outlined
            @click="currentTab -= 1"
          >
            Ubah Template Import
          </v-btn>
          <v-btn
            class="ml-auto"
            color="primary"
            :loading="loadingSubmit"
            @click="submit"
          >
            Import Produk
          </v-btn>
        </div>
      </div>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import { mdiClose, mdiFile } from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { uploadProduct } from '@/graphql/queries'
import { insertBatchProduct } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import DropFile from '@/components/inputs/DropFile.vue'

export default {
  components: {
    DropFile,
  },
  setup(props, { emit }) {
    const loadingUpload = ref(false)
    const loadingSubmit = ref(false)
    const isValid = ref(false)
    const filePicker = ref()
    const currentTab = ref(0)
    const products = ref([])

    const duplicateProduct = computed(() => products.value.filter(el => el.existing_product_id !== null))

    const submit = () => {
      loadingSubmit.value = true
      apolloClient.mutate({
        mutation: insertBatchProduct,
        variables: {
          data: products.value.map(el => ({
            existing_product_id: el.existing_product_id,
            name: el.name,
            description: el.description,
            status: el.status ? 1 : 0,
            price: el.price,
            stock: el.stock,
            code_sku: el.code_sku,
          })),
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        loadingSubmit.value = false
        currentTab.value = 0
        products.value = []
        Vue.notify({
          title: 'Berhasil!',
          text: 'Berhasil import data produk',
        })
        emit('success', result)
      }).catch(err => {
        errorHandling(err)
        loadingSubmit.value = false
      })
    }

    const upload = () => {
      const file = filePicker.value.getAcceptedFiles()

      if (file.length) {
        loadingUpload.value = true
        apolloClient.query({
          query: uploadProduct,
          variables: {
            file: file[0].file,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          loadingUpload.value = false

          if (result.data.uploadProduct && result.data.uploadProduct.length) {
            products.value = result.data.uploadProduct

            if (result.data.uploadProduct.every(el => el.existing_product_id === null)) {
              submit()
            } else {
              currentTab.value += 1
              console.log(currentTab.value)
            }
          }
        }).catch(err => {
          loadingUpload.value = false
          emit('error', err)
          errorHandling(err, 'Tambah Produk')
        })
      }
    }

    const deleteProduct = id => {
      Vue.$dialog({
        title: 'Hapus produk?',
        body: 'Konfirmasi jika anda ingin menghapus produk.',
      }).then(confirm => {
        if (confirm) {
          products.value = products.value.filter(el => el.existing_product_id !== id)
        }
      })
    }

    return {
      loadingUpload,
      loadingSubmit,
      isValid,
      filePicker,
      currentTab,
      products,
      duplicateProduct,

      submit,
      upload,
      deleteProduct,

      icons: {
        mdiFile,
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
