<template>
  <div>
    <v-card>
      <v-row
        class="mx-2 pt-2"
      >
        <v-col>
          <h3>Daftar Produk</h3>
        </v-col>
        <v-col
          cols="auto"
          class="ms-auto d-flex"
        >
          <input-icon-toggle
            v-model="state.productFilter.value.search"
            class="ms-2 me-2"
            :icon="icons.mdiMagnify"
            placeholder="Cari produk"
            @input="searchProduct()"
            @close="state.productFilter.value.search = ''; fetchProducts()"
          />
          <v-menu
            v-model="isArchiveOpen"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Produk Dihapus / Diarsipkan</span>
              </v-tooltip>
            </template>
            <ArchivedProduct
              :is-archive-open="isArchiveOpen"
              @update="fetchProducts()"
            />
          </v-menu>
          <v-icon
            size="26"
            @click="fetchProducts()"
          >
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        class="mx-2 mb-2"
      >
        <v-col
          cols="12"
          lg="3"
          md="4"
        >
          <v-select
            placeholder="Sortir Berdasarkan"
            :items="[]"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="3"
        >
          <v-badge
            :value="state.productFilter.value.count > 0"
            bordered
            overlap
            class="w-100 cursor-pointer"
            @click.native="$refs.productFilter.clearFilter()"
          >
            <template
              #badge
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <v-btn
              outlined
              block
              large
              :color="state.productFilter.value.count > 0 ? 'primary' : 'secondary'"
              @click.stop="$refs.productFilter.show()"
            >
              Filter {{ state.productFilter.value.count > 0 ? `(${state.productFilter.value.count})` : null }}
            </v-btn>
          </v-badge>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ms-0 ms-md-auto d-flex justify-space-between"
        >
          <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCardText }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Kartu</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-tooltip
            v-if="$can('create', 'Product')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <split-button
                class="ms-auto ms-md-4"
                color="primary"
                @click="$refs.productForm.show()"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <template #list>
                  <v-list-item @click="$refs.importProduct.show()">
                    Import Data Produk
                  </v-list-item>
                </template>
              </split-button>
            </template>
            <span>Tambah Produk</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-tabs-items v-model="viewTab">
        <v-tab-item>
          <v-data-table
            :headers="headers"
            :items="productList"
            :items-per-page="5"
            item-key="id"
            disable-sort
            :footer-props="{
              'items-per-page-options': [5, 10, 15],
              'items-per-page-text': 'Jumlah data',
            }"
            :server-items-length="productCount"
            mobile-breakpoint="0"
            :loading="loading"
            :options.sync="pagination"
          >
            <template v-slot:item.name="props">
              <div class="d-flex my-1">
                <v-img
                  :src="props.item.photo.length ? props.item.photo[0].photo : 'http://placekitten.com/140/110'"
                  class="rounded mr-2 my-1"
                  height="60px"
                  width="60px"
                  max-height="60px"
                  max-width="60px"
                />
                <div>
                  <router-link :to="{ name: 'product-detail', params: { id: props.item.id } }">
                    <span class="primary--text font-medium d-block">{{ props.item.name }}</span>
                  </router-link>
                  <small>{{ props.item.description }}</small>
                </div>
              </div>
            </template>
            <template v-slot:item.action="props">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="20px"
                    v-bind="attrs"
                    class="ml-auto"
                    v-on="on"
                  >
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </template>
                <v-list>
                  <router-link :to="{ name: 'product-detail', params: { id: props.item.id } }">
                    <v-list-item>Lihat Detail</v-list-item>
                  </router-link>
                  <v-list-item
                    v-if=" $can('update', 'Product')"
                    @click="$refs.productForm.update(props.item.id)"
                  >
                    Ubah Data
                  </v-list-item>
                  <v-list-item
                    v-if="$can('del', 'Product')"
                    class="error--text"
                    @click="deleteProduct(props.item.id)"
                  >
                    Hapus Produk
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template
              slot="footer.page-text"
              slot-scope="item"
            >
              Melihat {{ item.pageStart }} - {{ item.pageStop }} dari total {{ item.itemsLength }} data
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item class="mx-4">
          <v-data-iterator
            :headers="headers"
            :items="productList"
            :items-per-page="5"
            item-key="id"
            show-select
            disable-sort
            :footer-props="{
              'items-per-page-options': [5, 10, 15],
              'items-per-page-text': 'Jumlah data',
            }"
            :server-items-length="productCount"
            mobile-breakpoint="0"
            :loading="loading"
            :options.sync="pagination"
          >
            <template v-slot:default="props">
              <v-row class="match-height">
                <v-col
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                >
                  <v-hover #default="{ hover }">
                    <v-card
                      outlined
                      class="d-flex flex-column flex-grow-1 pa-4"
                      @click="$router.push({ name: 'product-detail', params: { id: item.id } })"
                    >
                      <div class="d-flex mb-1 align-start">
                        <div>
                          <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ item.name }}</span>
                          <span class="text-caption font-medium">Other Detail</span>
                        </div>
                        <v-menu
                          bottom
                          left
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                v-show="hover"
                                size="20px"
                                v-bind="attrs"
                                class="ml-auto mt-2"
                                v-on="on"
                              >
                                {{ icons.mdiDotsVertical }}
                              </v-icon>
                            </v-slide-x-reverse-transition>
                          </template>
                          <v-list>
                            <router-link :to="{ name: 'product-detail', params: { id: item.id } }">
                              <v-list-item>Lihat Detail</v-list-item>
                            </router-link>
                            <v-list-item
                              v-if="$can('update', 'Product')"
                              @click="$refs.productForm.update(item.id)"
                            >
                              Ubah Data
                            </v-list-item>
                            <v-list-item
                              v-if="$can('del', 'Product')"
                              class="error--text"
                              @click="deleteProduct(item.id)"
                            >
                              Hapus Produk
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template
              slot="footer.page-text"
              slot-scope="item"
            >
              Melihat {{ item.pageStart }} - {{ item.pageStop }} dari total {{ item.itemsLength }} data
            </template>
          </v-data-iterator>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <product-form
      ref="productForm"
      @success="fetchProducts()"
    />

    <import-product
      ref="importProduct"
      @success="fetchProducts()"
    />

    <product-filter
      ref="productFilter"
      @close="fetchProducts()"
    />
  </div>
</template>

<script>

import { ref, onMounted, watch } from '@vue/composition-api'
import {
  mdiPlus, mdiClose, mdiTable, mdiCardText, mdiDotsVertical,
  mdiMagnify, mdiRefresh, mdiArchive,
} from '@mdi/js'
import { useDebounceFn, useStorage } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import { products } from '@/graphql/queries'
import SplitButton from '@/components/inputs/SplitButton.vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import useProduct from './useProduct'
import ProductForm from './ProductForm.vue'
import errorHandling from '@/utils/errorHandling'
import ImportProduct from './ImportProduct.vue'
import ProductFilter from './ProductFilter.vue'
import router from '@/router'
import ArchivedProduct from '@/views/product/components/ArchivedProduct.vue'

const useFieldProduct = createFieldMapper({ getter: 'product/getField', setter: 'product/setField' })

export default {
  components: {
    ProductForm,
    SplitButton,
    InputIconToggle,
    ImportProduct,
    ProductFilter,
    ArchivedProduct,
  },
  setup() {
    const isArchiveOpen = ref(false)
    const loading = ref(false)
    const filterCount = ref(0)
    const headers = ref([
      {
        text: 'Nama',
        value: 'name',
        width: '300px',
      },
      {
        text: 'Harga',
        value: 'price',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Stock',
        value: 'stock',
      },
      {
        text: 'Platform',
        value: 'platform',
      },
      {
        text: '',
        value: 'action',
      },
    ])
    const productList = ref([])
    const productCount = ref(0)
    const viewTab = useStorage('viewTabProducts', 0)
    const { delProduct } = useProduct()
    const state = {
      ...useFieldProduct(['productFilter']),
    }
    const pagination = ref({})

    const fetchProducts = () => {
      const filter = state.productFilter.value
      loading.value = true
      apolloClient.query({
        query: products,
        fetchPolicy: 'no-cache',
        variables: {
          pagination: {
            limit: pagination.value.itemsPerPage ? pagination.value.itemsPerPage : 5,
            // eslint-disable-next-line no-nested-ternary
            offset: (pagination.value.page === 1 ? 0 : (pagination.value.page - 1) * pagination.value.itemsPerPage) ? (pagination.value.page === 1 ? 0 : (pagination.value.page - 1) * pagination.value.itemsPerPage) : 0,
          },
          search: filter.search ? filter.search : null,
          filter: {
            price: {
              max: filter.price.max ? +filter.price.max : null,
              min: filter.price.min ? +filter.price.min : null,
            },
            stock: {
              max: filter.stock.max ? +filter.stock.max : null,
              min: filter.stock.min ? +filter.stock.min : null,
            },
          },
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        productList.value = result.data.products.products
        productCount.value = result.data.products.count
        loading.value = false
      }).catch(err => {
        loading.value = false
        router.push('/error')
        errorHandling(err, 'Daftar Produk')
      })
    }

    const deleteProduct = id => {
      delProduct(id).then(() => {
        fetchProducts()
      }).catch(err => {
        errorHandling(err, 'Hapus Produk')
      })
    }

    const searchProduct = useDebounceFn(() => {
      fetchProducts()
    }, 1000)

    onMounted(() => {
      fetchProducts()
    })

    watch(pagination, () => {
      fetchProducts()
    })

    return {
      filterCount,
      loading,
      headers,
      viewTab,
      productList,
      productCount,
      fetchProducts,
      deleteProduct,
      state,
      searchProduct,
      pagination,
      isArchiveOpen,
      icons: {
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiDotsVertical,
        mdiMagnify,
        mdiRefresh,
        mdiArchive,
      },
    }
  },
}
</script>

<style></style>
