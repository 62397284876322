<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    scrollable
  >
    <v-card>
      <div class="pa-4 d-flex justify-space-between">
        <span class="text-subtitle-1 font-medium primary--text">Import Data Produk</span>
        <v-icon
          @click="isOpen = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-0">
        <v-tabs-items
          v-model="currentTab"
          touchless
        >
          <v-tab-item>
            <div v-if="currentTab === 0">
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card
                    outlined
                    class="pa-3 d-flex flex-column align-center justify-center"
                    height="200px"
                    @click="openImportForm('import-product-excel')"
                  >
                    <v-icon
                      size="56"
                      class="success--text mb-2"
                    >
                      {{ icons.mdiFile }}
                    </v-icon>
                    <span class="text-subtitle-2 text-center">
                      Import dari file Microsoft Excel / CSV
                    </span>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card
                    outlined
                    class="pa-3 d-flex flex-column align-center justify-center"
                    height="200px"
                    @click="openImportForm('import-product-shopee')"
                  >
                    <img
                      height="56"
                      class="mb-2"
                      src="https://oriens.node.zoomit.co.id/assets/img/platform/4/Shopee.png"
                    >
                    <span class="text-subtitle-2 text-center">
                      Import dari Shopee
                    </span>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card
                    outlined
                    class="pa-3 d-flex flex-column align-center justify-center"
                    height="200px"
                    @click="openImportForm('import-product-tokopedia')"
                  >
                    <img
                      height="56"
                      class="mb-2"
                      src="https://oriens.node.zoomit.co.id/assets/img/platform/2/Tokopedia.png"
                    >
                    <span class="text-subtitle-2 text-center">
                      Import dari Tokopedia
                    </span>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card
                    outlined
                    class="pa-3 d-flex flex-column align-center justify-center"
                    height="200px"
                    @click="openImportForm('import-product-accurate')"
                  >
                    <img
                      height="60"
                      class="mb-1"
                      src="https://oriens.node.zoomit.co.id/assets/img/platform/6/Accurate.png"
                    >
                    <span class="text-subtitle-2">
                      Import dari Accurate
                    </span>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <component
              :is="formPlatform"
              @previous="currentTab -= 1"
              @success="onSuccess"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiFile } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import ImportProductExcel from './components/ImportProductExcel.vue'

export default {
  components: {
    ImportProductExcel,
  },
  setup(props, { emit }) {
    const currentTab = ref(0)
    const isOpen = ref(false)
    const formPlatform = ref('')

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    const openImportForm = platform => {
      formPlatform.value = platform
      currentTab.value = 1
    }

    const onSuccess = () => {
      currentTab.value = 0
      emit('success')
      close()
    }

    return {
      currentTab,
      isOpen,
      formPlatform,

      show,
      close,
      openImportForm,
      onSuccess,
      avatarText,

      icons: {
        mdiClose,
        mdiFile,
      },
    }
  },
}
</script>

<style>

</style>
